import styled from 'styled-components';

export const HomeWrap = styled.section`
    text-align: center;
`;

export const CirclesWrap = styled.section`
    position: relative;
    height: 14.938rem;
    overflow: hidden;
    margin-top: 1rem;

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        height: 25rem;
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
        height: 37.375rem;
        margin-top: 0;
    }
`;

export const Circle = styled.div`
    border-radius: 50%;
    position: absolute;
    overflow: hidden;
    transform-origin: center;
    top: 50%;
    left: 50%;
`;

export const CircleOne = styled(Circle)`
    width: 180px;
    height: 180px;
    z-index: 70;
    transform: translate(-50%, -50%);

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        width: 588px;
        height: 588px;
    }
`;

export const CircleTwo = styled(Circle)`
    width: 117px;
    height: 117px;
    z-index: 60;
    transform: translate(-219px, -99px);

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        width: 282px;
        height: 282px;
        transform: translate(-576px, -281px);
    }
`;

export const CircleThree = styled(Circle)`
    width: 119px;
    height: 119px;
    z-index: 50;
    transform: translate(100px, -5px);

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        width: 282px;
        height: 282px;
        transform: translate(307px, -5px);
    }
`;

export const CircleFour = styled(Circle)`
    width: 73px;
    height: 73px;
    z-index: 40;
    transform: translate(-171px, 35px);

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        width: 180px;
        height: 180px;
        transform: translate(-484px, 35px);
    }
`;

export const CircleFive = styled(Circle)`
    width: 31px;
    height: 31px;
    z-index: 30;
    transform: translate(-214px, 29px);

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        width: 78px;
        height: 78px;
        transform: translate(-578px, 10px);
    }
`;

export const CircleSix = styled(Circle)`
    width: 71px;
    height: 71px;
    z-index: 20;
    transform: translate(94px, -87px);

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        width: 180px;
        height: 180px;
        transform: translate(313px, -240px);
    }
`;

export const CircleSeven = styled(Circle)`
    width: 27px;
    height: 27px;
    z-index: 10;
    transform: translate(185px, -40px);

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        width: 78px;
        height: 78px;
        transform: translate(487px, -104px);
    }
`;

export const Text = styled.div`
    margin-top: 2.188rem;

    &.fadeInUp {
        transition-delay: 1.1s;
    }

    p {
        font-size: 1.313rem;
        line-height: 2.188rem;
        letter-spacing: 0.022rem;

        &:last-child {
            font-size: 1rem;
            line-height: 1.25rem;
            letter-spacing: 0.016rem;
        }
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.mobile}) {
        p {
            font-size: 1.313rem;
            line-height: 2.188rem;
            letter-spacing: 0.022rem;

            &:last-child {
                font-size: 1rem;
                line-height: 1.5rem;
                letter-spacing: 0.016rem;
            }
        }
    }
`;
