import { useEffect, useRef } from 'react';

import { gsap } from 'gsap';
import Image from 'next/image';
import { useInView } from 'react-intersection-observer';

import ScrollReveal from '@/components/ScrollReveal';
import { IHomeHeroBlock } from '@/interfaces/pages';
import { Container } from '@/styles/global';
import useWindowSize, { Size } from 'src/hooks/useWindowSize';

import {
    CircleOne,
    CircleTwo,
    CircleThree,
    CircleFour,
    CircleFive,
    CircleSix,
    CircleSeven,
    CirclesWrap,
    HomeWrap,
    Text,
} from './styles';

interface IHomeHero {
    content: IHomeHeroBlock;
}

const HomeHero = ({ content }: IHomeHero) => {
    const { heading, copy, subText, images, background } = content;
    const size: Size = useWindowSize();

    const { ref: mainRef, inView: mainInView } = useInView({
        threshold: 0.5,
    });

    useEffect(() => {
        if (mainInView) {
            document.body.className = background;
        }
    }, [mainInView, background]);

    const circle1 = useRef();
    const circle2 = useRef();
    const circle3 = useRef();
    const circle4 = useRef();
    const circle5 = useRef();
    const circle6 = useRef();
    const circle7 = useRef();

    useEffect(() => {
        const circles = [
            circle1.current,
            circle2.current,
            circle3.current,
            circle4.current,
            circle5.current,
            circle6.current,
            circle7.current,
        ];

        gsap.set(circles, { clearProps: true, opacity: 1 });

        gsap.from(circles, {
            duration: 1,
            delay: 0.4,
            transformOrigin: 'center',
            xPercent: -50,
            yPercent: -50,
            x: 0,
            y: 0,
            left: '50%',
            top: '50%',
            width: 0,
            height: 0,
            stagger: 0.1,
            ease: 'power1.out',
            opacity: 0,
        });
    }, [size.width]);

    return (
        <HomeWrap ref={mainRef}>
            <CirclesWrap>
                <CircleOne ref={circle1}>
                    <Image src={images[0].path} alt={images[0].alt} width={588} height={588} priority unoptimized />
                </CircleOne>
                <CircleTwo ref={circle2}>
                    <Image src={images[1].path} alt={images[1].alt} width={282} height={282} priority unoptimized />
                </CircleTwo>
                <CircleThree ref={circle3}>
                    <Image src={images[2].path} alt={images[2].alt} width={282} height={282} priority unoptimized />
                </CircleThree>
                <CircleFour ref={circle4}>
                    <Image src={images[3].path} alt={images[3].alt} width={180} height={180} priority unoptimized />
                </CircleFour>
                <CircleFive ref={circle5}>
                    <Image src={images[4].path} alt={images[4].alt} width={78} height={78} priority unoptimized />
                </CircleFive>
                <CircleSix ref={circle6}>
                    <Image src={images[5].path} alt={images[5].alt} width={180} height={180} priority unoptimized />
                </CircleSix>
                <CircleSeven ref={circle7}>
                    <Image src={images[6].path} alt={images[6].alt} width={78} height={78} priority unoptimized />
                </CircleSeven>
            </CirclesWrap>
            <Container size="medium">
                <ScrollReveal>
                    <Text>
                        <h1>{heading}</h1>
                        <p>{copy}</p>
                        <p>{subText}</p>
                    </Text>
                </ScrollReveal>
            </Container>
        </HomeWrap>
    );
};

export default HomeHero;
